export const gateway = "https://coughlin-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://stable-brokers.auth.ca-central-1.amazoncognito.com/login?client_id=1d4sg9m3qc0bdu231c8s3vlj6t&response_type=token&scope=email+openid&redirect_uri=https%3A%2F%2Fcoughlin-bms.link.wawanesalife.com%2Fredirect";
export const HasLinkAdmin = "no";
export const BrokerPoolClientParameter = "1d4sg9m3qc0bdu231c8s3vlj6t";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const TopMenuLink1Caption = "Wawanesa Life";
export const TopMenuLink1Url = "https://www.wawanesalife.com";
export const TopMenuLink2Caption = "QuickTerm quoter";
export const TopMenuLink2Url = "https://coughlin.link.wawanesalife.com";

//updated on Thu Dec 12 2024 18:55:14 GMT-0600 (Central Standard Time)
